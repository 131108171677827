import Article from '@/models/article';
import News from '@/models/news';
import {reactive,ref} from 'vue';

export default class SharedStore {

  private _htmlAttrPrefix = ref("og: https://ogp.me/ns#");
  get htmlAttrPrefix() {
    return this._htmlAttrPrefix.value;
  }
  set htmlAttrPrefix(value) {
    this._htmlAttrPrefix.value = value;
  }

  private _htmlAttrLang = ref("ja");
  get htmlAttrLang() {
    return this._htmlAttrLang.value;
  }
  set htmlAttrLang(value) {
    this._htmlAttrLang.value = value;
  }

  private _metaTitle = ref("");
  get metaTitle() {
    return this._metaTitle.value;
  }
  set metaTitle(value) {
    this._metaTitle.value = value;
  }

  private _isTop = ref(true);
  get isTop() {
    return this._isTop.value;
  }
  set isTop(value) {
    this._isTop.value = value;
  }

  private _headline = reactive<News[]>([]);
  get headline() {
    return this._headline;
  }
  set headline(value) {
    this._headline = value;
  }

  private _news = reactive<News[]>([]);
  get news() {
    return this._news;
  }
  set news(value) {
    this._news = value;
  }

  private _about = ref<Article>(new Article());
  get about() {
    return this._about.value;
  }
  set about(value) {
    this._about.value = value;
  }

  private _achievement = ref<Article>(new Article());
  get achievement() {
    return this._achievement.value;
  }
  set achievement(value) {
    this._achievement.value = value;
  }

  private _jobHunting = ref<Article>(new Article());
  get jobHunting() {
    return this._jobHunting.value;
  }
  set jobHunting(value) {
    this._jobHunting.value = value;
  }

  private _article = ref<Article>(new Article());
  get article() {
    return this._article.value;
  }
  set article(value) {
    this._article.value = value;
  }

}