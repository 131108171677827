import moment from 'moment';
import 'moment/locale/ja';

export default class DateTime{
  public static localDate(utcDate: Date, formatString = ''){
    if(formatString === ''){
      formatString = 'YYYY-MM-DD HH:mm:ss';
    }

    return moment(utcDate).locale('ja').local().format(formatString);
  }

  public static toISO8601(utcDate: Date){
    return moment(utcDate).format("YYYY-MM-DD HH:mm:ssZ");
  }
}