<template>
  <div id="main_visual" class="slider_wrapper" >
    <transition :name="state.animeMode" :duration="500">
      <div class="slide slide3" v-if="state.curPos===0" :key="0">
        <!-- fithor -->
        <div class="slide_inner">
          <div class="slide_title">
            <img class="fithor_icon" src="~@/assets/images/fithor_round_border.svg" alt="Fithor" />
            <img class="fithor_logotype" src="~@/assets/images/fithor_logotype.svg" />
          </div>
          <p>筋トレの記録やスケジュールを管理する</p>
          <ul class="store">
            <li><a href="https://apps.apple.com/jp/app/fithor/id1524260548" class="store_apple"><img src="~@/assets/images/appstore.svg" alt="app store"></a></li>
            <li><a href="https://play.google.com/store/apps/details?id=jp.kamusoft.hathor" class="store_google"><img src="~@/assets/images/google_play.svg" alt="google play"></a></li>
          </ul>
        </div>
      </div>
      <div class="slide slide1" v-else-if="state.curPos===1" :key="1">
        <!-- 復習帳 -->
        <div class="slide_inner">
          <div class="slide_title">
            <img src="~@/assets/images/review_note.svg" />
            <span>復習帳</span>
          </div>
          <p>学習内容を忘れないように<br>効率の良い復習をサポート</p>
          <ul class="store">
            <li><a href="https://itunes.apple.com/jp/app/%E5%BE%A9%E7%BF%92%E5%B8%B3-%E5%BF%98%E3%82%8C%E3%82%8B%E5%89%8D%E3%81%AB%E5%BE%A9%E7%BF%92%E3%82%92%E9%80%9A%E7%9F%A5%E3%81%97%E3%81%A6%E5%8A%B9%E7%8E%87%E3%81%AE%E3%82%88%E3%81%84%E5%8B%89%E5%BC%B7%E3%82%92%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88/id1219259567?mt=8" class="store_apple"><img src="~@/assets/images/appstore.svg" alt="app store"></a></li>
            <li><a href="https://play.google.com/store/apps/details?id=jp.kamusoft.reviewnote" class="store_google"><img src="~@/assets/images/google_play.svg" alt="google play"></a></li>
          </ul>
        </div>
      </div>
      <div class="slide slide2" v-else-if="state.curPos===2" :key="2">
        <!-- 色しらべ -->
        <div class="slide_inner">
          <div class="slide_title">
            <img src="~@/assets/images/color_analyzer.svg" />
            <span>色しらべ</span>
          </div>
          <p>カメラで日常の色を調べる<br><br></p>
          <ul class="store">
            <li><a href="https://itunes.apple.com/jp/app/%E8%89%B2%E3%81%97%E3%82%89%E3%81%B9-%E3%82%AB%E3%83%A1%E3%83%A9%E3%81%A7%E8%89%B2%E3%81%AE%E6%83%85%E5%A0%B1%E3%82%92%E6%8A%BD%E5%87%BA-%E9%85%8D%E8%89%B2%E3%81%AE%E8%A7%A3%E6%9E%90/id1160206848?mt=8" class="store_apple"><img src="~@/assets/images/appstore.svg" alt="app store"></a></li>
            <li><a href="https://play.google.com/store/apps/details?id=jp.kamusoft.coloranalyzer" class="store_google"><img src="~@/assets/images/google_play.svg" alt="google play"></a></li>
          </ul>
        </div>
      </div>
    </transition>
    <div id="slider_ctrl">
      <a v-for="pos in state.positions" :key="pos" :class="{current:pos===state.curPos}" @click="showItem(pos)" ></a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, reactive } from 'vue';
import Hammer from 'hammerjs';

let hmManager: HammerManager | null = null;

export default defineComponent({
  name: 'LoopSlider',
  setup() {
    const state = reactive({
      animeMode: 'fade',
      curPos: 0,
      current: false,
      positions: [0, 1, 2],
      timer: 0,
      intarval: 15000,
    });

    onMounted(() => {
      const element = document.getElementById('main_visual') || new HTMLElement();
      hmManager = new Hammer.Manager(element);
      const swipe = new Hammer.Swipe({threshold: 20, direction: Hammer.DIRECTION_HORIZONTAL});
      hmManager.add(swipe);
      hmManager.on('swipeleft', swipeNext);
      hmManager.on('swiperight', swipePrev);
      state.timer = window.setInterval(showNext, state.intarval);
    });    

    onUnmounted(() => {
      clearInterval(state.timer);
      hmManager?.off('swipeleft swiperight');
      hmManager?.destroy();
    });
    
    const showNext =  () => {
      let pos = state.curPos + 1;
      if (pos > state.positions.length - 1) {
        pos = 0;
      }
      // 自動再生はスライド
      state.animeMode = 'move-left';
      state.curPos = pos;
    }

    const showPrev = () => {
      let pos = state.curPos - 1;
      if (pos < 0) {
        pos = state.positions.length - 1;
      }
      state.animeMode = 'move-right';
      state.curPos = pos;
    }

    const showItem = (pos: number) => {
      if (pos === state.curPos) {
        return;
      }
      console.log('fire');
      clearInterval(state.timer); // クリア
      // ボタンによる切り替えはフェードで
      state.animeMode = 'fade';
      state.curPos = pos;
      state.timer = window.setInterval(showNext, state.intarval); // タイマー再セット
    }

    const swipeNext = () => {
      clearInterval(state.timer);
      showNext();
      state.timer = window.setInterval(showNext, state.intarval);
    }
    const swipePrev = () => {
      clearInterval(state.timer);
      showPrev();
      state.timer = window.setInterval(showNext, state.intarval);
    }
    
    return{
      state,
      showItem
    }
  }
});


</script>

<style lang="scss" scoped>

.slider_wrapper{
  position: relative;
  max-width: 1280px;
  width: 100%;
  background-color: g.$PaleBackEx;
  overflow: hidden;
  @include g.Media(g.$Pc){
    max-height: 540px;
  }
  &:before{
    content: "";
    display: block;
    @include g.Media(g.$Pc){
      padding-top: calc( 100% * 540 / 1280 );
    }
    @include g.Media(g.$Sp){
      padding-top: calc( 100vw * 540 / 640 + 220px );
    }
  }
}

.slide{
    position: absolute;
    top:0px;
    left:0px;
    bottom:0px;
    right:0px;
    background-repeat: no-repeat, no-repeat;
    background-position: right 10% center, left top;
    background-size: auto 81%, 100% auto;
    display: flex;
    justify-content: flex-start;
    @include g.Media(g.$Sp){
      justify-content: center;
      background-position: center calc(100vw * 0.81 * 0.1), left top;
      background-size: 90% auto, 100% auto;
    }
}

.slide_inner{
  position: relative;
  margin: 0 auto;
  padding: 20px 24px 16px 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  @include g.Media(g.$Pc){
    max-width: 1024px;
    min-width: 800px;
    flex-grow: 1;
  }
  @include g.Media(g.$Sp){
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-top:calc(100vw * 540 / 640);
    padding-bottom: 0;
  }

  .slide_title{
    display: inline-flex;
    align-items: center;
    height: 50px;
    text-align: center;
    @include g.Media(g.$Sp){
      margin-left: -36px;
    }
    img{
      width: 44px;
      height: 44px;
      @include g.Media(g.$Sp){
        width: 36px;
        height: 36px;
      }
    }
    span{
      margin-left: 8px;
      margin-top: 2px;
      font-size: 40px;
      font-family: g.$RoundFontSet;
      @include g.Media(g.$Sp){
        font-size: 32px;
      }
    }
  }
  >p{
    margin-top: 6px;
    color: g.$Gy45;
    font-size: 22px;
    @include g.Media(g.$Pc){
      flex-grow: 1;
    }
    @include g.Media(g.$Sp){
      font-size: 20px;
      margin-top: 12px;
    }
  }
  .store{
    margin-top: 16px;
    display: flex;
    align-items:center;
    @include g.Media(g.$Sp){
      width: 100%;
      justify-content: center;
      margin-top: 12px;
    }
    li{
      @include g.Media(g.$Pc){
        margin-right:30px;
      }
      @include g.Media(g.$Sp){
        margin: 0 10px 0 10px;
      }
    }
  }
}

#slider_ctrl{
  position: absolute;
  bottom: 10px;
  right: 8px;
  z-index: 999;
  display: block;
  @include g.Media(g.$Sp){
    display: none;
  }
  a{
    border-radius: 8px 8px;
    width: 8px;
    height: 8px;
    display: block;
    background-color: lightgray;
    margin-bottom: 4px;
    cursor: pointer;
    &.current{
      background-color:lightcoral !important;
      cursor: default !important;
    }
    &:hover{
      background-color: orange;
    }
  }
}

.slide1{
  background-image: url("~@/assets/images/review_note_fg.png"),url("~@/assets/images/review_note_bg.jpg");
  @include g.Media(g.$Sp){
    background-image: url("~@/assets/images/review_note_fg.png"),url("~@/assets/images/review_note_bg_sp.jpg");
  }
  .slide_title span{
    color: #F7931E;
  }
}
.slide2{
  background-image: url("~@/assets/images/color_analyzer_fg.png"),url("~@/assets/images/color_analyzer_bg.jpg");
  @include g.Media(g.$Sp){
    background-image: url("~@/assets/images/color_analyzer_bg_sp.jpg");
    background-position: left top;
    background-size: 100% auto;
  }
  .slide_title span{
    color: g.$Gy45;
  }
}
.slide3{
  background-image: url("~@/assets/images/fithor_fg.png"),url("~@/assets/images/fithor_bg.jpg");
  @include g.Media(g.$Sp){
    background-image: url("~@/assets/images/fithor_fg.png"),url("~@/assets/images/fithor_bg_sp.jpg");
  }
  .fithor_icon {
    width: 54px !important;
    height: 54px !important;
    @include g.Media(g.$Sp){
      width: 36px;
      height: 36px;
    }
  }
  .fithor_logotype {
    margin-left:20px;
    width: 133px !important;
    height: 50px !important;
    @include g.Media(g.$Sp){
      width: 36px;
      height: 36px;
    }
  }
}

.fade-enter-active, .fade-leave-active{
  transition: all 0.5s ease;
}
.fade-enter-from, .fade-leave-to{
  opacity: 0;
}
.move-left-enter-active, .move-left-leave-active{
  transition: all 0.5s ease-in-out;
}
.move-left-enter-from{
  transform:translateX(100%);
}
.move-left-enter-to, .move-left-leave-from{
  transform:translateX(0);
}
.move-left-leave-to{
  transform:translateX(-100%);
}
.move-right-enter-active, .move-right-leave-active{
  transition: all 0.5s ease;
}
.move-right-enter-from{
  transform:translateX(-100%);
}
.move-right-enter-to, .move-right-leave-from{
  transform:translateX(0);
}
.move-right-leave-to{
  transform:translateX(100%);
}
</style>
