import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/news/:id',
    name: 'News',
    component: () => import(/* webpackChunkName: "news" */ '../views/News.vue')
  },
  {
    path: '/newslist',
    name: 'NewsList',
    component: () => import(/* webpackChunkName: "newslist" */ '../views/NewsList.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },  
  {
    path: '/achievement',
    name: 'Achievement',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "achievement" */ '../views/Achievement.vue')
  }, 
  {
    path: '/job_hunting',
    name: 'JobHunting',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "jobhunting" */ '../views/JobHunting.vue')
  }, 
  {
    path: '/redirect',
    name: 'Redirect',
    component: () => import(/* webpackChunkName: "redirect" */ '../views/Redirect.vue')
  },
  {
    path: '/reviewnote/:path*',
    redirect: to => {
      // 外部へのリダイレクト
      window.location.href = `https://reviewnote.kamusoft.jp/${to.params.path}`;
      return { path: '/redirect' };
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "notfound" */ '../views/NotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
