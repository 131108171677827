<template>
  <header id="global_header" :style="{zIndex:state.zIndex}">
    <div id="menu_opener">
      <div v-if="shared.isTop"><h1><router-link class="logo" to="/"></router-link></h1></div>
      <div v-else><router-link class="logo" to="/"></router-link></div>
      <button id="menu_opener_button" v-on:click="menuToggle">
        <transition name="fade">
          <div class="menu opened" key="opened" v-if="state.menuIsOpen"></div>
          <div class="menu closed" key="closed" v-else></div>
        </transition>
      </button>
      <nav id="menu_container">
        <ul>          
          <li><a href="https://docs.fithor.net/">Fithor</a></li>
          <li><a href="https://reviewnote.kamusoft.jp/ja/">復習帳</a></li>
          <li><a href="http://color.kamusoft.jp/">色しらべ</a></li>
          <li><a href="https://github.com/muak">ライブラリ</a></li>
          <li><router-link to="/about">開発者について</router-link></li>
        </ul>
      </nav>
    </div>
  </header>
  <teleport to="#modal">
    <transition name="modal" mode="out-in">
      <div class="modal" v-if="state.menuIsOpen"></div>
    </transition>
  </teleport>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, onUnmounted, reactive, watch, ref } from 'vue';
import {useStore} from '@/models/container';
import Anime from 'animejs';

export default defineComponent({
  name: 'GlobalHeader',

  setup(){
    const state = reactive({
      zIndex: 100,
      menuIsOpen: false,
    });
    const { shared } = useStore();

    const menuToggle = () => {
      state.menuIsOpen = !state.menuIsOpen;
    };

    const resized = () => {
      if(state.menuIsOpen){
        state.menuIsOpen = false;
      }
    };

    watch(() => state.menuIsOpen,(newValue: boolean) => {
      const menuH = document.getElementById('menu_container')?.clientHeight || 0;
      const target = '#menu_opener';

      if(newValue){
        Anime({
          targets: target,
          height: '+=' + menuH,
          duration: 250,
          easing: 'easeInSine'
        });
        state.zIndex = 9999;        
      }
      else{
        Anime({
          targets: target,
          height: '-=' + menuH,
          duration: 250,
          easing: 'easeInSine',
          complete: function (anim) {
            state.zIndex = 100;
          }
        });
      }
    });

    onBeforeMount(()=>{
      window.addEventListener("resize",resized,false);
    });
    onUnmounted(() => {
      window.removeEventListener("resize",resized,false);
    });

    return {
      state,
      shared,
      menuToggle
    }
  }
});
</script>

<style lang="scss" scoped>

#global_header{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: auto;
  background-color: g.$DeepBack;
  // font-size: 15px
  #menu_opener{
    display: flex;
    max-width: 1280px;
    height: 50px;
    margin: 0 auto;
    @include g.Media(g.$Sp){
      display: block;
      min-height: 50px;
      height: 0;
      overflow: hidden;
    }
    .logo{
      display: block;
      height: 50px;
      width: 171px;
      margin-left: 20px;
      background-image: url("~@/assets/images/kamusoft_logo.svg");
      background-repeat: no-repeat;
      background-size: 171px 50px;
      background-position: 0 center;
    }
    >button{
      @include g.Media(g.$Pc){
        display: none;
      }
      @include g.Media(g.$Sp){
        position: absolute;
        top: 0px;
        right: 0px;
        height: 50px;
        width: 50px;
      }
    }
    >nav{
      flex-grow: 1;
      margin-left: 30px;
      @include g.Media(g.$Sp){
        display: block;
        margin-left: 0px;
        background-color: #fff;
      }
      >ul{
        display: flex;
        height: 50px;
        padding: 10px 0 6px 0;
        justify-content: space-around;
        align-items: flex-end;
        @include g.Media(g.$Sp){
          padding: 0;
          display: block;
          height: auto;
        }
        >li {
          @include g.Media(g.$Sp){
            border-bottom: 1px solid g.$DeepBack;
            &:last-child{
              border:none;
            }
          }
          a{
            font-size: 15px;
            color: #fff;
            text-decoration: none;
            @include g.Media(g.$Sp){
              font-size: 18px;
              display: block;
              color: g.$DeepBack;
              padding: 16px 16px 16px 26px;
            }
          }
        }
      }
    }
  }
}             

#menu_opener_button{
  position: relative;
}

div.menu{
  position: absolute;
  top:0;
  left:0;
  bottom: 0;
  right:0;
  background-position: center center;
  background-size: 36px 36px;
  width: 100%;
  height: 100%;
}
div.closed{
  background-image: url("~@/assets/images/menu.svg");
}
div.opened{
  background-image: url("~@/assets/images/close.svg");
  background-size: 32px 32px;
}
.fade-enter-active, .fade-leave-active{
  transition: all 0.3s ease;
}
.fade-enter-from,.fade-leave-to{
  opacity: 0;
  transform: scale(0.4);
}
.fade-enter-to, .fade-leave-from{
  opacity: 1;
  transform: scale(1);
}

.modal{
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9000;
}
.modal-enter-from{
  opacity: 0;
}
.modal-enter-to{
  opacity: 1;
  transition: all 150ms ease;
}
.modal-leave-from{
  opacity: 1;
}
.modal-leave-to{
  opacity: 0;
  transition: all 150ms ease;
}
</style>
