/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance } from 'axios';
import News from './news';
import SharedStore from '@/stores/shared';
import Article from './article';

export interface IApi{
  newsHeadline: () => Promise<void>;
  newsArticle: (id: string) => Promise<void>;
  about: () => Promise<void>;
  achievement: () => Promise<void>;
  jobHunting: () => Promise<void>;
}

export class Api implements IApi{
  private api: AxiosInstance;
  private store!: SharedStore;

  constructor(store: SharedStore){
    this.store = store;
    this.api = axios.create({
      timeout: 10000,
      baseURL: location.origin,
    });
  }

  public async newsHeadline(){
    // 取得済みなら何もしない
    if(this.store.news.length > 0){
      return;
    }

    const response = await this.api.get('api/news_index.json');

    if(response.status != 200){
      throw new Error("api newsHeadline failed");
    }

    const json = response.data;   
  
    // 再代入するとReactiveが反応しないのでPushで1つずつ代入する
    json.map((item: any) => new News(item))
    .sort((a: News,b: News) => {
      return a.postedAt > b.postedAt ? -1 : 1; // 日付降順ソート
    })
    .forEach((v: News) => this.store.news.push(v));

    // 最大3件をheadlineに追加
    this.store.news.slice(0,3).forEach((v: News) => this.store.headline.push(v));
  }

  public async newsArticle(id: string){
      const response = await this.api.get(`api/news/${id}.json`);

      if(response.status != 200){        
        throw new Error();
      }

      const json = response.data;   

      this.store.article = new Article(json);   
  }

  public async about(){
    // 取得済みなら何もしない
    if(!this.store.about.isEmpty){
      return;
    }

    const response = await this.api.get('api/about.json');

    if(response.status != 200){
      throw new Error("api about failed");
    }

    const json = response.data;
    this.store.about = new Article(json);
  }

  public async achievement(){
    // 取得済みなら何もしない
    if(!this.store.achievement.isEmpty){
      return;
    }

    const response = await this.api.get('api/achievement.json');

    if(response.status != 200){
      throw new Error("api about failed");
    }

    const json = response.data;
    this.store.achievement = new Article(json);
  }

  public async jobHunting(){
    // 取得済みなら何もしない
    if(!this.store.jobHunting.isEmpty){
      return;
    }

    const response = await this.api.get('api/job_hunting.json');

    if(response.status != 200){
      throw new Error("api about failed");
    }

    const json = response.data;
    this.store.jobHunting = new Article(json);
  }
}