/* eslint-disable @typescript-eslint/no-explicit-any */
import DateTime from '@/models/dateTime';

export default class News{
  public id = 0;
  public title = '';
  public externalLink = '';
  public postedAt!: Date;
  public url = '';
  public target = '';
  public utcDate = '';
  public localDate = '';

  constructor(json?: any){
    if(json === undefined){
      return;
    }

    this.id = json.Id;
    this.title = json.Title || '';
    this.externalLink = json.ExternalLink || '';
    this.postedAt = new Date(json.PostedAt);
    this.utcDate = DateTime.toISO8601(this.postedAt);
    this.localDate = DateTime.localDate(this.postedAt,"YYYY.MM.DD");

    this.url = `/news/${this.id}`;
    this.target = '_self';
    if(this.externalLink != ''){
      this.url = this.externalLink;
      this.target = '_blank';
    }
  }
}