<template>
  <GlobalHeader />
  <div id="main">
    <router-view></router-view>
  </div>
  <GlobalFooter />  
</template>

<script lang="ts">
import { defineComponent, computed, watchEffect } from 'vue';
import { useStore } from '@/models/container';
import GlobalHeader from '@/components/Header.vue';
import GlobalFooter from '@/components/Footer.vue';

export default defineComponent({
  components: {
    GlobalHeader,
    GlobalFooter
  },
  setup() {
    const { shared } = useStore();

    // 動的にMeta Titleを設定する
    watchEffect(()=>{
      document.title = `${shared.metaTitle} | kamusoft`;
    });
  }
});
</script>

<style lang="scss">
#main{
  position: relative;
  margin: 50px auto 0px auto;
  padding-bottom: 30px;
  flex-grow: 1;
  max-width: 1280px;
  width: 100%;
}
</style>
