// DIコンテナ代わり
// ここでInjectionKeyの設定とInjectのラップ関数を定義する

import { StoreRoot } from "@/stores/root";
import { inject, InjectionKey } from "vue";
import { IApi } from "./api";

export const StoreRootKey: InjectionKey<StoreRoot> = Symbol('StoreRoot');
export function useStore(){
  const store = inject(StoreRootKey);
  if(!store){
    throw new Error(`${StoreRootKey} is not provided.`);    
  }
  return store;
}

export const ApiKey: InjectionKey<IApi> = Symbol('Api');
export function useApi(){
  const api = inject(ApiKey);
  if(!api){
    throw new Error(`${ApiKey} is not provided.`);    
  }
  return api;
}