/* eslint-disable @typescript-eslint/no-explicit-any */
import DateTime from '@/models/dateTime';

export default class Article{
  public id = 0;
  public postedAt!: Date;
  public title = '';
  public body = '';
  public utcDate = '';
  public localDate = '';
  public isEmpty = true;

  constructor(json?: any){
    if(json === undefined){
      return;
    }

    this.id = json.Id;
    this.title = json.Title || '';
    this.body = json.Body || '';
    this.postedAt = new Date(json.PostedAt);    
    this.utcDate = DateTime.toISO8601(this.postedAt);
    this.localDate = DateTime.localDate(this.postedAt,"YYYY.MM.DD");
    this.isEmpty = false;
  }
}