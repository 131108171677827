<template>
<LoopSlider />
<div class="main_container info_container">
  <div class="info_header">
    <h2>新着情報</h2>
    <router-link to="/newslist">
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         width="20px" height="20px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
      <path d="M322.7,128.4L423,233.4c6,5.8,9,13.7,9,22.4c0,8.7-3,16.5-9,22.4L322.7,383.6c-11.9,12.5-31.3,12.5-43.2,0
        c-11.9-12.5-11.9-32.7,0-45.2l48.2-50.4h-217C93.7,288,80,273.7,80,256c0-17.7,13.7-32,30.6-32h217l-48.2-50.4
        c-11.9-12.5-11.9-32.7,0-45.2C291.4,115.9,310.7,115.9,322.7,128.4z"/>
      </svg>
      <span>一覧へ</span>
    </router-link>
  </div>
  <ul>
    <li v-for="(item,index) in shared.headline" :key="index">
      <time :datetime="item.utcDate">{{item.localDate}}</time>
      <router-link :to="item.url" :target="item.target">{{item.title}}</router-link>
    </li>
  </ul>
</div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount,onMounted } from 'vue';
import LoopSlider from '@/components/LoopSlider.vue';
import { useStore, useApi } from '@/models/container';

export default defineComponent({
  name: 'Home', 
  components: {
    LoopSlider
  },

  setup() {
    const { shared } = useStore();
    const api = useApi();
    
    
    // ここで直接APIを呼ぶ処理を書く
    api.newsHeadline();

    onBeforeMount(()=>{
      shared.metaTitle = 'トップ';
    });    

    // reactiveな状態の変更はマウント後に有効
    onMounted(()=>{
      // トップページフラグON
      shared.isTop = true;
    });
   
    return{
      shared
    }
  }
});
</script>


<style lang="scss" scoped>
.main_container{
  margin: 0 auto;
  @include g.Media(g.$Pc){
    max-width: 1024px;
    min-width: g.$Pc;
  }
  @include g.Media(g.$Sp){
    width: 100%;
  }
}

.info_container{
  margin-top:16px;
  .info_header{
    display: flex;
    padding: 3px 0 3px 16px;
    border-bottom: g.$DeepBack 3px solid;
    width: 100%;
    >h2{
      flex-grow: 1;
      font-size: g.$FontSizeL;
      color: g.$DeepFore;
    }
    >a{
      display: flex;
      align-items: center;
      @include g.AnchorReset(g.$DeepFore);
      svg{
        display: block;
        fill: g.$DeepBack;
      }
    }
  }
  >ul{
    padding: 16px;
    > li{
      display: flex;
      padding-bottom: 8px;
      @include g.Media(g.$Sp){
        flex-direction: column;
        padding-bottom: 1em;
      }
      >time{
        margin-right: 32px;
        @include g.Media(g.$Sp){
          font-size: g.$FontSizeS;
        }
      }
      >a{
        flex-grow: 1;
        @include g.AnchorReset();
      }
    }
  }
}
</style>