<template>
    <footer id="global_footer">
      <nav>
        <ul class="footer_banner_link">
          <li>
            <a href="https://github.com/muak" class="github">
              <img src="~@/assets/images/github.svg" alt="GitHub" />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/muak_x" class="twitter">
              <img src="~@/assets/images/twitter.svg" alt="Twitter" />
            </a>
          </li>
          <li>
            <a href="https://kamusoft.hatenablog.jp/" class="hatenablog">
              <img src="~@/assets/images/hatenablog.svg" alt="はてなブログ" />
            </a>
          </li>
          <li>
            <a href="https://qiita.com/muak_x" class="qiita">
              <img src="~@/assets/images/qiita.svg" alt="Qiita" />
            </a>
          </li>
        </ul>
        <ul class="footer_text_link">
          <li>
            <a href="https://itunes.apple.com/jp/developer/satoshi-nakamura/id1160206847">AppStore開発者ページ</a>
          </li>
          <li>
            <a href="https://play.google.com/store/apps/developer?id=kamusoft">GooglePlay開発者ページ</a>
          </li>
          <li>
            <a href="https://www.nuget.org/profiles/kamu">nugetプロフィール</a>
          </li>
        </ul>
        <ul class="footer_bottom">
          <li></li>
          <li>&copy; {{Year}} kamusoft</li>
        </ul>
      </nav>
    </footer>
</template>

<script lang="ts">
import { defineComponent,ref } from 'vue';
import moment from 'moment';

export default defineComponent({
  name: 'GlobalFooter',
  setup(){
    const Year = ref<string>(moment().format("YYYY"));

    return {
      Year
    }
  }
});
</script>

<style lang="scss">
#global_footer{
  width: 100%;
  background-color: g.$PaleBack;
  >nav{
    margin: 0 auto;
    padding: 4px;
    @include g.Media(g.$Pc){
      max-width: 1024px;
      min-width: g.$Pc;
    }
    @include g.Media(g.$Sp){
      width: 100%;
    }
    >ul{
      display: flex;
      margin: 0 auto;
    }
    .footer_banner_link{
      justify-content: center;
      flex-wrap: wrap;
      a{
        @include g.AnchorReset();
        display:block;
        background-color: #fff;
        height:48px;
        margin: 8px;
      }
      .hatenablog{
        img{
          height:48px;
        }
      }
      .qiita{
        padding:11px;
        img{
          height: 26px;
        }
      }
      .github{
        padding:6px;
        img{
          height:36px;
        }
      }
      .twitter{
        img{
          height: 48px;
        }
      }
    }
    .footer_text_link{
      justify-content: center;
      flex-wrap: wrap;
      a{
        @include g.AnchorReset();
        margin: 0 8px 0 8px;
        font-size: g.$FontSizeS;
      }
    }
    .footer_bottom{
      width: 100%;
      justify-content: space-between;
      li{
        font-size: g.$FontSizeS;
        color: g.$DeepFore;
      }
      a{
        @include g.AnchorReset(g.$DeepFore);
        font-size: g.$FontSizeS;
      }
    }
  }
}
</style>