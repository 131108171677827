import '@/assets/sass/main.scss'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import storeRoot from '@/stores/root'
import {Api} from '@/models/api';
import { ApiKey, StoreRootKey } from './models/container'

const app = createApp(App);
const root = storeRoot();

// DI登録
app.provide(StoreRootKey, root);
app.provide(ApiKey,new Api(root.shared)); 

app
.use(router)
.mount('#app');
